.Section {
    height: 100%;
    width: 25vw;
    transition: width .5s, font-size .5s, padding .5s, margin .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.75rem;
    transform: skew(10deg);
  }
  
  .Section:hover {
    width: 85%;
    padding: 1rem 0 0 0;
    font-size: 5rem;
    font-size: 3rem;
  }

  .Section__Title {
    position: fixed;
    transform: skew(350deg);
    top: 3rem;
    font-size: 5rem;
    color: white;
  }

  .Section__Title.true {
    opacity: 100;
    transition: opacity .75s;
  }

  .Section__Title.false {
    opacity: 0;
    transition: opacity .2s;
  }

  .Section__Icon {
    position: absolute;
    transform: skew(350deg);
    margin-bottom: 70%;
  }

  .Section__Icon.true {
    opacity: 0;
    transition: all .3s;
  }

  .Section__Icon.false {
    opacity: 100;
    transition: all 1s;
  }
  
  .Section.Web {
    background-color: #283845;
    font-family: 'Lobster', cursive;
  }
  
  .Section.Applications {
    background-color: #b8b08d;
    font-family: 'Bebas Neue', cursive;
    transition: all .5s;
  }
  
  .Section.Automation {
    background-color: #f2d492;
    font-family: 'Gugi', cursive;
  }
  
  .Section.Contact {
    background-color: #f29559;
    font-family: 'Amatic SC', cursive;
  }
  
  .Section__Content__GridItem-container {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .Section__Content {
    color: azure;
    position: relative;
    height: 50%;
    width: 50%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    font-size: 1.5rem;
    transform: skew(350deg);
    transition: opacity .25s, margin-top .25s;
  }
  
  .Section__Content.true {
    min-width: 1fr;
  }
  
  .Section__Content.false {
    opacity: 0;
    transition: opacity .25s,  margin-top .25s;
    transition-timing-function: ease-in-out;
    margin-top: 300%;
  }
  
  .Section__Content--icon {
    transition: height .5s;
    padding-bottom: 2px;
    padding-right: 2px;
    height: 6rem;
  }
  
  .Section__Content--icon.true {
    opacity: 100;
    height: 6rem;
  }
  
  .Section__Content--icon.false {
    opacity: 100;
  }
  
  .Section__Content__GridItem {
    width: 75%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    
  }
  
  .Section__Content__GridItem.r1c1 {
    text-align: left;
    width: 100%;
    height: 100%;
    border-radius: 0%;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .Section__Content__GridItem.r1c2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  
  .Section__Content__GridItem.r2c1 {
    grid-column: span 2;
  }