.Header {
    z-index: 999999999;
    position: fixed;
    top: 25%;
    font-size: 8rem;
    color: azure;
    background-color: rgb(245, 94, 94);    
    transition: all .3s;
    padding: 0 2rem 0 2rem;
    text-align: center;
    overflow-wrap: initial;
    text-overflow: ellipsis;
    width: 25vw;
}

.Header.true {
    box-sizing: border-box;
    font-size: 1rem;
    top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden;
}

.Header.false {
    opacity: 100;
}